import React from "react";
import { Link } from "react-router-dom";
import PaymentError from "../../assets/img/payment/Payment-Error.webp";

const Error = () => {
  //---> Payment error from url params
  const paymentError = localStorage.getItem("paymentError");

  return (
    <div className="d-flex flex-column align-items-center justify-content-center text-center">
      <div className="w-100" style={{ maxWidth: "396px" }}>
        <img src={PaymentError} alt="root-billing" className="img-fluid" />
      </div>
      <div className="mt-4 p-1">
        <h1 className="fw-bold text-danger"> {paymentError}</h1>
        <p
          className="text-secondary"
          style={{ maxWidth: "70%", margin: "0 auto" }}
        >
          Please try again at a later stage.
        </p>
        <Link to="/dashboard" className="btn btn-danger mt-4">
          Try Again
        </Link>
      </div>
    </div>
  );
};

export default Error;
