import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Clock, TelephoneFill } from "react-bootstrap-icons";
import { ispOwnerSupportNumbers } from "../../features/customer";
import TdLoader from "../../components/common/TdLoader";

const SupportNumber = () => {
  const dispatch = useDispatch();

  //---> @Get customer information from redux store
  const userData = useSelector(
    (state) => state.persistedReducer.auth?.currentUser.customer
  );

  //---> @Get ispOwner support number from redux store
  const numbers = useSelector((state) => state.customer.numbers);

  //====================|| Local State ||====================//

  //---> Loading state
  const [isLoading, setIsLoading] = useState(false);

  //====================|| Api Call's ||====================//
  useEffect(() => {
    //---> @Get IspOwner business support number
    !numbers?.length &&
      ispOwnerSupportNumbers(dispatch, userData?.ispOwner?.id, setIsLoading);
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between p-3 bg-primary bg-opacity-75">
        <div className="text-white fs-5">Support Number's</div>
      </div>

      {isLoading ? (
        <TdLoader />
      ) : numbers?.length > 0 ? (
        numbers?.map((val) => (
          <div className="support_team shadow-sm bg-body rounded gap-2 my-2">
            <div className="d-flex align-items-center">
              <img
                src="./assets/img/noAvater.jpg"
                alt=""
                className="support_person"
              />

              <div className="support_info">
                <span className="fw-normal">{val.name}</span>
                <span className="fw-normal">
                  <TelephoneFill className="text-primary" /> {val.mobile}
                </span>
              </div>
            </div>

            <div className="d-flex flex-column align-items-center gap-2">
              <span className="small fw-bold text-success">{val.start}</span>
              <span className="small fw-bold text-danger">{val.end}</span>
            </div>
          </div>
        ))
      ) : (
        <h6 className="text-center my-3">No Data Found!</h6>
      )}
    </div>
  );
};

export default SupportNumber;
