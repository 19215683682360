import React, { useEffect, useState } from "react";
import "./profile.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { badge } from "../../components/common/Utils";
import { PersonLock } from "react-bootstrap-icons";
import ChangePassword from "./ChangePassword";
import { ToastContainer } from "react-toastify";
import { getCustomerProfile } from "../../features/customer";

const Profile = () => {
  const dispatch = useDispatch();

  //---> @Get customer information from redux store
  const userData = useSelector(
    (state) => state.persistedReducer.auth?.currentUser.customer
  );

  //---> @Get customer current profile information from redux store
  const customer = useSelector((state) => state.customer?.customer);

  //====================|| Local State ||====================//

  //---> Loading state
  const [isLoading, setIsLoading] = useState(false);

  //---> Modal handle state
  const [show, setShow] = useState(false);

  //---> User password reset redirect time
  const [redirectTime, setRedirectTime] = useState(10);
  const [startRedirect, setStartRedirect] = useState(false);

  //====================|| Api Call's ||====================//
  useEffect(() => {
    //---> @Get customer current profile information
    !Object.keys(customer)?.length &&
      getCustomerProfile(dispatch, userData?.id, setIsLoading);
  }, []);

  return (
    <>
      <ToastContainer position="top-right" theme="colored" />
      <div>
        <div class="profile-card d-flex justify-content-end">
          <img
            src="./assets/img/noAvater.jpg"
            class="profile-pic"
            alt="Profile Picture"
          />
          <div className="d-flex gap-3 p-2 text-white">
            <div
              title="Change Password"
              className="cursor-pointer"
              onClick={() => setShow(true)}
            >
              <PersonLock size={22} />
            </div>
          </div>
        </div>

        <div className="d-flex flex-column align-items-center mt-5">
          <div className="displayGridHorizontalFill5_5 profileDetails">
            <span>ID:</span>
            <span>{userData?.customerId}</span>
          </div>

          {startRedirect && (
            <div>
              <h5
                className={`font-bold text-center ${
                  redirectTime > 3 ? "text-success" : "text-danger"
                }`}
              >
                you are being logging out in {redirectTime} seconds...
              </h5>
            </div>
          )}
        </div>

        <div class="settings-section">
          <h6>Profile Headline</h6>
          <div class="settings-item">
            <span class="settings-icon">Name</span>
            {userData?.name?.toUpperCase()}
          </div>
          <div class="settings-item">
            <span class="settings-icon">Mobile</span>
            {userData?.mobile}
          </div>
          <div class="settings-item">
            <span class="settings-icon">Package</span>
            {customer?.pppoe?.profile}
          </div>
          <div class="settings-item">
            <span class="settings-icon">Monthly Fee</span>৳
            {customer?.monthlyFee}
          </div>
          <div class="settings-item">
            <span class="settings-icon">Balance</span>৳{userData?.balance}
          </div>
        </div>

        <div class="settings-section">
          <h6>Profile Detail</h6>
          <div class="settings-item">
            <span class="settings-icon">Status</span>
            {badge(customer?.status?.toUpperCase())}
          </div>
          <div class="settings-item">
            <span class="settings-icon">Payment Status</span>
            {badge(customer?.paymentStatus?.toUpperCase())}
          </div>
          {userData?.address && (
            <div class="settings-item">
              <span class="settings-icon">Address</span>
              {userData?.address}
            </div>
          )}
        </div>

        <div class="settings-section">
          <h6>Current State</h6>
          <div class="settings-item">
            <span class="settings-icon">Billing Date</span>
            {moment(customer?.billingCycle).format("MMM DD YYYY hh:mm A")}
          </div>
          <div class="settings-item">
            <span class="settings-icon">Promise Date</span>
            {moment(customer?.promiseDate).format("MMM DD YYYY hh:mm A")}
          </div>
          <div class="settings-item">
            <span class="settings-icon">Connection Date</span>
            {moment(userData?.connectionDate).format("MMM DD YYYY hh:mm A")}
          </div>
          <div class="settings-item">
            <span class="settings-icon">Create Date</span>
            {moment(userData?.createdAt).format("MMM DD YYYY hh:mm A")}
          </div>
        </div>
      </div>

      {show && (
        <ChangePassword
          show={show}
          setShow={setShow}
          setStartRedirect={setStartRedirect}
          setRedirectTime={setRedirectTime}
        />
      )}
    </>
  );
};

export default Profile;
