const addClass = {
  PAID: "success",
  UNPAID: "warning text-dark",
  EXPIRED: "danger",
  ACTIVE: "primary",
  INACTIVE: "secondary",
};

export const badge = (item) => {
  return <span className={`badge bg-${addClass[item]}`}>{item}</span>;
};
