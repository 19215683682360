import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: null,
  role: null,
  ispOwnerId: "",
  userData: {},
  isFetching: false,
  error: false,
  accessToken: null,
  ispOwnerData: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logInStart: (state) => {
      state.isFetching = true;
    },

    logInSuccess: (state, action) => {
      state.accessToken = action.payload?.access.token;
      state.isFetching = false;
      state.currentUser = action.payload;
      state.role = action.payload?.user.role;
    },

    loginFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    logOut: (state) => {
      state.currentUser = null;
      state.accessToken = null;
      state.role = null;
      state.ispOwnerId = "";
      state.userData = {};
    },
  },
});

export const { logInStart, logInSuccess, loginFailure, logOut } =
  authSlice.actions;

export default authSlice.reducer;
