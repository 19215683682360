import React, { useState } from "react";
import ComponentCustomModal from "../../components/common/customModal/ComponentCustomModal";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Loader from "../../components/common/Loader";
import { createCustomerSupportTicket } from "../../features/customer";
import { useDispatch } from "react-redux";

const SupportTicketCreate = ({ show, setShow, customer }) => {
  const dispatch = useDispatch();

  //---> Validation Schema
  const validationSchema = Yup.object({
    message: Yup.string().required("Type Your Problem"),
  });

  //====================|| Local State ||====================//

  //---> Loading state
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { message: "" },
  });

  //====================|| Function ||====================//

  //---> Customer support ticket create handler
  const handleCustomerSupportTicketCreate = (formValues) => {
    const sendingData = {
      ...formValues,
      ispOwner: customer?.ispOwner.id,
      customer: customer?.id,
      ...(customer?.reseller && { reseller: customer?.reseller }),
    };

    //---> Customer support ticket create api
    createCustomerSupportTicket(dispatch, sendingData, setIsLoading, setShow);
  };

  return (
    <ComponentCustomModal show={show} setShow={setShow} centered>
      <form
        className="displayGrid"
        onSubmit={handleSubmit(handleCustomerSupportTicketCreate)}
      >
        <Controller
          name="message"
          control={control}
          render={({ field }) => (
            <textarea
              className="form-control"
              {...field}
              rows={5}
              placeholder="Type Your Problem..."
            />
          )}
        />
        {errors.message && (
          <p className="text-danger">{errors.message.message}</p>
        )}

        <div className="displayGrid1 float-end">
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => setShow(false)}
            disabled={isLoading}
          >
            Cancel
          </button>

          <button
            type="submit"
            className="btn btn-outline-success"
            disabled={isLoading}
          >
            {isLoading ? <Loader /> : "Submit"}
          </button>
        </div>
      </form>
    </ComponentCustomModal>
  );
};

export default SupportTicketCreate;
