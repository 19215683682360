import axios from "axios";
import jwt_decode from "jwt-decode";
import { userLogout } from "../features/customer";

// PRODUCTION
const LIVE_PRODUCTION_URL = "https://netfeebd.net/api/v1/";

//===> DEVELOPMENT URL
const SEYAM_DEV_URL = "http://localhost:9031/api/v1/";

//=================|| SELECT DEVELOPMENT URL ||=================//
const DEV_URL = LIVE_PRODUCTION_URL;
// const DEV_URL = SEYAM_DEV_URL;

//=================|| EXPORTING BASE URL ||=================//
export const baseURL =
  process.env.NODE_ENV == "development" ? DEV_URL : LIVE_PRODUCTION_URL;

export const publicRequest = axios.create({
  baseURL: baseURL,
});

// const TOKEN = JSON.parse(localStorage.getItem("netFeeToken"))

const apiLink = axios.create({
  baseURL: baseURL,
});

apiLink.interceptors.request.use(
  async (config) => {
    const TOKEN = await JSON.parse(localStorage.getItem("netFeeToken"));

    if (!TOKEN) {
      userLogout();
      localStorage.removeItem("netFeeToken");
    }

    let currentDate = new Date();
    const decodedToken = jwt_decode(TOKEN);

    // const recentUser = localStorage.getItem("nfAUsr");

    // if (recentUser) {
    //   // auth update logout
    //   const user = JSON.parse(recentUser);
    //   const tokenUser = JSON.parse(localStorage.getItem("tokenUsr"));
    //   const tokenUserUpdateTime = new Date(tokenUser.updatedAt).getTime();
    //   const getUserUpdateTimeFormApiCall = new Date(user.updatedAt).getTime();

    //   if (tokenUserUpdateTime < getUserUpdateTimeFormApiCall) {
    //     console.log("dhukse na");
    //     userLogout();
    //   }
    // }

    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      // await refreshToken();
      // config.baseURL = BASE_URL;
      // const TOKEN = await JSON.parse(localStorage.getItem("netFeeToken"));

      // config.headers["authorization"] = "Bearer " + TOKEN;
      userLogout();
    } else {
      config.headers["authorization"] = "Bearer " + TOKEN;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// apiLink.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (err) {
//     // console.log(err?.response);
//     // console.log(err.response?.status);
//     // userLogout();
//     if (err.response?.status === 401) {
//       userLogout();
//     }

//     return Promise.reject(err);
//   }
// );

export default apiLink;
