import React from "react";
import { NavLink } from "react-router-dom";
// Import icons from react-icons
import "./footer.css";
import { Cash, PersonFill, Speedometer2 } from "react-bootstrap-icons";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <NavLink to="/profile" className="footer-item" activeClassName="active">
          <PersonFill className="footer-icon" />
          <span>Profile</span>
        </NavLink>

        <NavLink
          to="/dashboard"
          className="footer-item"
          activeClassName="active"
        >
          <Speedometer2 className="footer-icon" />
          <span>Dashboard</span>
        </NavLink>

        <NavLink to="/report" className="footer-item" activeClassName="active">
          <Cash className="footer-icon" />
          <span>Payment</span>
        </NavLink>
      </div>
    </footer>
  );
};
