import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";
import { badge } from "../Utils";

const ComponentCustomModal = ({
  show,
  setShow,
  backdrop,
  keyboard,
  centered,
  size,
  header,
  status,
  paymentStatus,
  printr,
  children,
  footer,
}) => {
  //---> Modal close handler
  const handleClose = () => setShow(false);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop={backdrop}
      keyboard={keyboard}
      centered={centered}
      size={size}
    >
      {header && (
        <ModalHeader closeButton>
          <ModalTitle>
            <div className="d-flex gap-2">
              <p>{header}</p>
              <div>{printr && printr}</div>
              <small>{badge(status)}</small>
              <small>{badge(paymentStatus)}</small>
            </div>
          </ModalTitle>
        </ModalHeader>
      )}

      <ModalBody>{children}</ModalBody>
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </Modal>
  );
};

export default ComponentCustomModal;
