import React, { useEffect, useState } from "react";
import {
  getCustomerChangePackage,
  getPackagesByIspOwner,
  updateCustomerPackage,
} from "../../features/customer";
import { useDispatch, useSelector } from "react-redux";
import TdLoader from "../../components/common/TdLoader";
import Loader from "../../components/common/Loader";
import { ArrowClockwise } from "react-bootstrap-icons";
import ComponentCustomAlert from "../../components/common/customModal/ComponentCustomAlert";

const Package = () => {
  const dispatch = useDispatch();

  //---> @Get customer information from redux store
  const userData = useSelector(
    (state) => state.persistedReducer.auth?.currentUser.customer
  );

  //---> @Get ispOwner all package from redux store
  const packages = useSelector((state) => state.customer.packages);

  //---> @Get customer package change request from redux store
  const changePackage = useSelector((state) => state.customer.changePackage);

  //====================|| Local State ||====================//

  //---> Loading state
  const [isLoading, setIsLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  //---> Modal handle state
  const [show, setShow] = useState(false);

  //---> New package data state
  const [newPackage, setNewPackage] = useState({});

  //====================|| Function ||====================//

  //---> Customer previous package update request handler
  const handleCustomerPreviousPackageUpdate = () => {
    const sendingData = {
      customer: userData?.id,
      mikrotikPackage: newPackage?.id,
      name: userData?.name,
      mobile: userData?.mobile,
      customerId: userData?.customerId,
      previousPackage: userData?.mikrotikPackage,
      status: "pending",
      ...(!userData?.reseller && { ispOwner: userData?.ispOwner?.id }),
      ...(userData?.reseller && { reseller: userData?.reseller }),
    };

    //---> Customer package change request api call
    updateCustomerPackage(sendingData, setUpdateLoading, setShow);
  };

  //====================|| Api Call's ||====================//
  useEffect(() => {
    //---> @Get IspOwner all business package
    !packages?.length && getPackagesByIspOwner(dispatch, setIsLoading);

    //---> @Get customer package change
    !Object.keys(changePackage)?.length &&
      getCustomerChangePackage(dispatch, userData?.id, setUpdateLoading);
  }, []);

  return (
    <>
      <div>
        <div className="d-flex justify-content-between p-3 bg-primary bg-opacity-75">
          <div className="text-white fs-5">Packages</div>
        </div>

        {isLoading ? (
          <TdLoader />
        ) : packages?.length > 0 ? (
          packages?.map((item) => (
            <div
              className="d-flex justify-content-between p-2 shadow-sm my-2"
              key={item?.id}
            >
              <div className="d-flex flex-column">
                <span className="small">
                  {item?.aliasName ? item?.aliasName : item?.name}
                </span>
                <span className="small fw-light">{item?.rate} TK /Month</span>
              </div>
              <div className="d-flex align-items-center">
                {item.id === userData?.mikrotikPackage ? (
                  <button className="btn btn-success btn-sm">Active</button>
                ) : (
                  <button
                    className={`btn btn-sm ${
                      item.id === changePackage?.mikrotikPackage
                        ? "btn-warning"
                        : "btn-outline-primary"
                    }`}
                    onClick={() => {
                      if (
                        !(
                          changePackage?.mikrotikPackage &&
                          item.id === changePackage?.mikrotikPackage
                        )
                      ) {
                        setShow(true);
                        setNewPackage(item);
                      }
                    }}
                    disabled={
                      changePackage?.mikrotikPackage
                        ? item.id === changePackage?.mikrotikPackage
                          ? false
                          : true
                        : false
                    }
                  >
                    {item.id === changePackage?.mikrotikPackage
                      ? "Pending"
                      : "Change"}
                  </button>
                )}
              </div>
            </div>
          ))
        ) : (
          <h6 className="text-center my-3">No Data Found!</h6>
        )}
      </div>

      <ComponentCustomAlert
        header="Package Change"
        show={show}
        setShow={setShow}
        centered={true}
        onSubmit={handleCustomerPreviousPackageUpdate}
        isLoading={updateLoading}
      >
        Are you want to update your package
      </ComponentCustomAlert>
    </>
  );
};

export default Package;
