import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";
import Loader from "../Loader";

const ComponentCustomAlert = ({
  show,
  setShow,
  backdrop,
  keyboard,
  centered,
  size,
  header,
  children,
  onSubmit,
  isLoading,
}) => {
  //---> Modal close handler
  const handleClose = () => setShow(false);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop={backdrop}
      keyboard={keyboard}
      centered={centered}
      size={size}
    >
      {header && (
        <ModalHeader closeButton>
          <ModalTitle>
            <div className="d-flex gap-2">
              <p>{header}</p>
            </div>
          </ModalTitle>
        </ModalHeader>
      )}

      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <div className="displayGrid1 float-end">
          <button
            type="button"
            className="btn btn-secondary border-0"
            disabled={isLoading}
            onClick={() => setShow(false)}
          >
            Cancel
          </button>

          <button
            type="button"
            form="createCustomer"
            className="btn btn-success border-0"
            onClick={onSubmit}
            disabled={isLoading}
          >
            {isLoading ? <Loader /> : "Submit"}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ComponentCustomAlert;
