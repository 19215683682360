import { createSlice } from "@reduxjs/toolkit";

const customerApiSlice = createSlice({
  name: "customer",
  initialState: {
    customer: {},
    currentSession: {},
    routerConnectivity: {},
    packages: [],
    changePackage: {},
    reports: [],
    numbers: [],
    tickets: [],
    staffs: [],
    category: [],
  },
  reducers: {
    getCustomerProfileSuccess: (state, action) => {
      state.customer = action.payload;
    },

    getCustomerCurrentSessionSuccess: (state, action) => {
      state.currentSession = action.payload;
    },

    getCustomerRouterConnectivitySuccess: (state, action) => {
      state.routerConnectivity = action.payload;
    },

    getIspOwnerPackageSuccess: (state, action) => {
      state.packages = action.payload;
    },

    getCustomerChangePackageSuccess: (state, action) => {
      state.changePackage = action.payload;
    },

    getCustomerBillReportSuccess: (state, action) => {
      state.reports = action.payload;
    },

    getIspOwnerSupportNumberSuccess: (state, action) => {
      state.numbers = action.payload;
    },

    getCustomerSupportTicketSuccess: (state, action) => {
      state.tickets = action.payload;
    },

    postCustomerSupportTicketSuccess: (state, action) => {
      state.tickets.push(action.payload);
    },

    getIspOwnerStaffSuccess: (state, action) => {
      state.staffs = action.payload;
    },

    getIspOwnerTicketCategorySuccess: (state, action) => {
      state.category = action.payload;
    },
  },
});

export const {
  getCustomerProfileSuccess,
  getCustomerCurrentSessionSuccess,
  getCustomerRouterConnectivitySuccess,
  getIspOwnerPackageSuccess,
  getCustomerChangePackageSuccess,
  getCustomerBillReportSuccess,
  getIspOwnerSupportNumberSuccess,
  getCustomerSupportTicketSuccess,
  postCustomerSupportTicketSuccess,
  getIspOwnerStaffSuccess,
  getIspOwnerTicketCategorySuccess,
} = customerApiSlice.actions;
export default customerApiSlice.reducer;
