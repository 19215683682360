import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  ArrowClockwise,
  BoxArrowRight,
  ChatText,
  Collection,
  Headset,
  ListCheck,
  PersonCircle,
  ThreeDotsVertical,
} from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerCurrentSession,
  getCustomerProfile,
  getCustomerRouterConnectivity,
  userLogout,
} from "../../features/customer";
import { useNavigate } from "react-router-dom";
import { getCustomerDayLeft } from "./customerBillDay";
import TdLoader from "../../components/common/TdLoader";
import Payment from "../payment/Payment";
import { toast, ToastContainer } from "react-toastify";
import LiveBandwidth from "../live-bandwidth/LiveBandwidth";
import { badge } from "../../components/common/Utils";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //---> @Get customer information from redux store
  const userData = useSelector(
    (state) => state.persistedReducer.auth?.currentUser.customer
  );

  //---> @Get customer current profile information from redux store
  const customer = useSelector((state) => state.customer?.customer);

  //---> @Get customer current session from redux store
  const customerSession = useSelector(
    (state) => state.customer?.currentSession
  );

  //---> @Get customer router connectivity from redux store
  const customerConnectivity = useSelector(
    (state) => state.customer?.routerConnectivity
  );

  //====================|| Local State ||====================//

  //---> Loading state
  const [isLoading, setIsLoading] = useState(false);
  const [sessionLoading, setSessionLoading] = useState(false);

  //---> Modal handle state
  const [show, setShow] = useState(false);

  //====================|| Function ||====================//

  //---> Dashboard page refresh
  const handleDashboardComponentRefresh = () => {
    //---> @Get customer current profile information
    getCustomerProfile(dispatch, userData?.id, setIsLoading);

    //---> @Get customer router active connection
    getCustomerRouterConnectivity(dispatch, setSessionLoading);

    //---> @Get customer router session interface
    getCustomerCurrentSession(dispatch, userData?.id, setSessionLoading);
  };

  //---> Customer profile logout handler
  const handlerCustomerProfile = () => {
    userLogout();
  };

  //====================|| Api Call's ||====================//
  useEffect(() => {
    //---> @Get customer current profile information
    !Object.keys(customer)?.length &&
      getCustomerProfile(dispatch, userData?.id, setIsLoading);

    //---> @Get customer router active connection
    userData?.ispOwner?.bpSettings?.hasMikrotik &&
      getCustomerRouterConnectivity(dispatch, setSessionLoading);

    //---> @Get customer router session interface
    userData?.ispOwner?.bpSettings?.hasMikrotik &&
      getCustomerCurrentSession(dispatch, userData?.id, setSessionLoading);
  }, []);

  return (
    <>
      <ToastContainer position="top-right" theme="colored" />
      <div>
        <div className="d-flex justify-content-between p-3 bg-primary bg-opacity-75">
          <div className="text-white fs-5">Dashboard</div>
          <div className="d-flex align-items-center gap-2 text-white">
            <div>
              <ArrowClockwise
                size={22}
                title="Refresh"
                onClick={handleDashboardComponentRefresh}
              />
            </div>

            <div className="dropdown">
              <ThreeDotsVertical
                size={22}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              />

              <ul className="dropdown-menu">
                <li onClick={() => navigate("/profile")}>
                  <div className="dropdown-item">
                    <div className="d-flex gap-2 align-items-center">
                      <PersonCircle />
                      <p>Profile</p>
                    </div>
                  </div>
                </li>
                <li onClick={handlerCustomerProfile}>
                  <div className="dropdown-item">
                    <div className="d-flex gap-2 align-items-center">
                      <BoxArrowRight />
                      <p>Logout</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          {isLoading && (
            <div
              className={`d-flex justify-content-center align-items-center Loader ${
                isLoading && "d-block"
              }`}
            >
              <TdLoader />
            </div>
          )}

          <div className="text-center text-black py-2">
            <span className="fs-5 fw-normal d-block">
              {userData?.ispOwner?.company}
            </span>
            <span className="text-muted">{userData?.name}</span>
          </div>

          <div className="d-flex justify-content-between align-items-center px-2">
            <div>
              <span className="small">Monthly Bill:</span>
              <span className="d-block fs-6 fw-semibold">
                {userData?.monthlyFee} Tk.
              </span>
            </div>

            <button
              type="button"
              className="btn btn-outline-primary rounded-pill px-3 py-1"
              onClick={() => navigate("/package")}
            >
              Package
            </button>
          </div>

          <hr className="text-muted my-0" />

          <div className="d-flex justify-content-between align-items-center px-2">
            <div>
              <span className="small">Balance:</span>
              <span className="d-block fs-6 fw-semibold text-info">
                {customer?.balance > 0 ? customer?.balance : 0} Tk.
              </span>
            </div>

            <div>
              <span className="small">Status:</span>
              <span
                className={`d-block text-${
                  customer?.status === "active"
                    ? "primary"
                    : customer?.status === "inactive"
                    ? "secondary"
                    : "danger"
                }`}
              >
                {customer?.status?.toUpperCase()}
              </span>
            </div>
          </div>

          <hr className="text-muted my-0" />

          <div className="d-flex justify-content-between align-items-center px-2">
            <div>
              <span className="small">Due:</span>
              <span className="d-block fs-6 fw-semibold text-danger">
                {customer?.balance < 0 ? Math.abs(customer?.balance) : 0} Tk.
              </span>
            </div>

            <button
              type="button"
              className="btn btn-primary rounded-pill px-3 py-1"
              style={{ opacity: "75%" }}
              onClick={() => {
                if (userData?.ispOwner?.bpSettings?.hasPG) {
                  setShow(true);
                } else {
                  toast.info("Contact Bill Man.");
                }
              }}
            >
              Pay Bill
            </button>
          </div>

          <hr className="text-muted my-0" />

          <div className="d-flex justify-content-between align-items-center px-2">
            <div>
              <span className="small">Last Date:</span>
              <span className="d-block fs-6 fw-semibold">
                {moment(customer?.billingCycle).format("DD-MM-YYYY hh:mm A")}
              </span>
            </div>

            <div className="text-center p-1">
              <p
                className={`d-flex align-items-center justify-content-center rounded-circle ${
                  getCustomerDayLeft(customer?.billingCycle) >= 20
                    ? "border border-3 border-success text-success"
                    : getCustomerDayLeft(customer?.billingCycle) >= 10
                    ? "border border-3 border-primary text-primary"
                    : getCustomerDayLeft(customer?.billingCycle) >= 0
                    ? "magantaColor"
                    : "bg-danger text-white"
                }`}
                style={{ width: "50px", height: "50px" }}
              >
                {getCustomerDayLeft(customer?.billingCycle)}
              </p>
            </div>
          </div>

          <hr class="text-muted mt-0" />

          <div className="d-flex gap-2 p-2 text-white">
            <div
              className="col p-2 bg-primary bg-opacity-75"
              onClick={() => navigate("/report")}
            >
              <Collection size={25} />
              <p className="small">Reports</p>
            </div>

            <div
              className="col p-2 bg-success bg-opacity-75"
              onClick={() => navigate("/package")}
            >
              <ListCheck size={25} />
              <p className="small">Packages</p>
            </div>

            <div
              className="col p-2 bg-info bg-opacity-75"
              onClick={() => navigate("/support/ticket")}
            >
              <ChatText size={25} />
              <p className="small">Support Tickets</p>
            </div>

            <div
              className="col p-2 bg-danger bg-opacity-75"
              onClick={() => navigate("/support/number")}
            >
              <Headset size={25} />
              <p className="small">Support Numbers</p>
            </div>
          </div>

          {userData?.ispOwner?.bpSettings?.hasMikrotik && (
            <>
              <hr class="text-muted my-0" />

              {!isLoading && sessionLoading ? (
                <div class="d-flex justify-content-between align-items-center placeholder-glow px-2">
                  <div>
                    <span class="small">Last Link Uptime:</span>
                    <span class="placeholder w-100 rounded-pill"></span>
                  </div>

                  <div>
                    <span class="small">Uptime:</span>
                    <span class="placeholder w-100 rounded-pill"></span>
                  </div>
                </div>
              ) : (
                <div class="d-flex justify-content-between align-items-center px-2">
                  <div>
                    <span class="small">Last Link Uptime:</span>
                    <span class="d-block fs-6 fw-semibold">
                      {moment(
                        customerSession?.lastLinkUpTime
                          ? customerSession?.lastLinkUpTime
                          : customer?.billingCycle
                      ).format("DD-MM-YYYY hh:mm A")}
                    </span>
                  </div>

                  <div>
                    <span class="small">Uptime:</span>
                    <span class="d-block fs-6 fw-normal">
                      {customerConnectivity?.uptime
                        ? customerConnectivity?.uptime
                        : "0h0m0s"}
                    </span>
                  </div>
                </div>
              )}

              <hr class="text-muted my-0" />

              {!isLoading && sessionLoading ? (
                <div class="d-flex justify-content-between align-items-center placeholder-glow px-2">
                  <div>
                    <span class="small">Download:</span>
                    <span class="placeholder w-100 rounded-pill"></span>
                  </div>

                  <div>
                    <span class="small">Upload:</span>
                    <span class="placeholder w-100 rounded-pill"></span>
                  </div>
                </div>
              ) : (
                <div class="d-flex justify-content-between align-items-center px-2">
                  <div>
                    <span class="small">Download:</span>
                    <span class="d-block fs-6 fw-normal">
                      {customerSession?.rxByte
                        ? (customerSession?.rxByte / 1024 / 1024).toFixed(2) +
                          " MB"
                        : `${0} MB`}
                    </span>
                  </div>

                  <div>
                    <span class="small">Upload:</span>
                    <span class="d-block fs-6 fw-normal">
                      {customerSession?.txByte
                        ? (customerSession?.txByte / 1024 / 1024).toFixed(2) +
                          " MB"
                        : `${0} MB`}
                    </span>
                  </div>
                </div>
              )}

              <hr class="text-muted my-0" />

              <div className="mt-2">
                <LiveBandwidth customer={userData} />
              </div>
            </>
          )}
        </div>
      </div>

      {show && <Payment show={show} setShow={setShow} customer={customer} />}
    </>
  );
};

export default Dashboard;
