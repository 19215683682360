import React, { useEffect, useState } from "react";
import { getCustomerBillReport } from "../../features/customer";
import { useDispatch, useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
import TdLoader from "../../components/common/TdLoader";
import moment from "moment";
import { FontColor } from "../../assets/js/theme";
import "../client.css";

const Report = () => {
  const dispatch = useDispatch();

  //---> @Get customer bill reports from redux store
  const reports = useSelector((state) => state.customer.reports);

  //====================|| Local State ||====================//

  //---> Loading state
  const [isLoading, setIsLoading] = useState(false);

  //====================|| Api Call's ||====================//
  useEffect(() => {
    //---> @Get Customer bill reports
    !reports?.length && getCustomerBillReport(dispatch, setIsLoading);
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between p-3 bg-primary bg-opacity-75">
        <div className="text-white fs-5">Reports</div>
      </div>

      {isLoading ? (
        <TdLoader />
      ) : reports?.length > 0 ? (
        reports?.map((val) => {
          return (
            <Accordion className="m-1">
              <Accordion.Item eventKey={val?.user}>
                <div className="reportHeader">
                  <Accordion.Button className="reportAccordion">
                    <p className="reportName">
                      {val?.month} &nbsp;
                      {new Date(val?.createdAt).getFullYear()}
                    </p>
                  </Accordion.Button>
                </div>

                <Accordion.Body className="p-0">
                  <div className="shadow-sm bg-white rounded">
                    <h5 className="reportCollect mb-0">{val?.name}</h5>

                    <FontColor>
                      <div className="report5_5 collectReport">
                        <div className="reportOptions row gt-1">
                          <p>
                            Amount
                            <b>{val?.amount}</b>
                          </p>

                          <p>
                            Bill Type <b>{val?.billType}</b>
                          </p>

                          <p>
                            Medium <b>{val?.medium}</b>
                          </p>

                          <p>
                            Discount <b>{val?.discount}</b>
                          </p>

                          <p>
                            Due <b>{val?.due}</b>
                          </p>

                          <p>
                            Previous Balance
                            <b>{val?.prevState?.balance}</b>
                          </p>

                          <p>
                            Current Balance
                            <b>{val?.currentState?.balance}</b>
                          </p>
                        </div>

                        <div className="reportOptions">
                          {/* customer bill report create date */}
                          <p>
                            Create Date
                            <b>
                              {moment(val.createdAt).format(
                                "MMM DD YYYY hh:mm A"
                              )}
                            </b>
                          </p>

                          {/* customer previous billing & promise date state */}
                          <p className="clintTitle border border-1 ps-1">
                            Previous State
                          </p>

                          <p className="mt-1">
                            Bill Date
                            <b>
                              {moment(val.prevState?.billingCycle).format(
                                "MMM DD YYYY hh:mm A"
                              )}
                            </b>
                          </p>

                          <p>
                            Promise Date
                            <b>
                              {moment(val.prevState?.promiseDate).format(
                                "MMM DD YYYY hh:mm A"
                              )}
                            </b>
                          </p>

                          {/* customer current billing & promise date state */}
                          <p className="clintTitle border border-1 ps-1 mt-1">
                            Current State
                          </p>

                          <p className="mt-1">
                            Bill Date
                            <b>
                              {moment(val.currentState?.billingCycle).format(
                                "MMM DD YYYY hh:mm A"
                              )}
                            </b>
                          </p>

                          <p>
                            Promise Date
                            <b>
                              {moment(val.currentState?.promiseDate).format(
                                "MMM DD YYYY hh:mm A"
                              )}
                            </b>
                          </p>
                        </div>
                      </div>
                    </FontColor>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          );
        })
      ) : (
        <h6 className="text-center my-3">No Data Found!</h6>
      )}
    </div>
  );
};

export default Report;
