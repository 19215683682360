import React, { useEffect, useState } from "react";
import {
  getCustomerSupportTicket,
  getIspOwnerTicketCategory,
  getIspOwnerUsers,
} from "../../features/customer";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/common/Loader";
import { ArrowClockwise, PlusCircle } from "react-bootstrap-icons";
import TdLoader from "../../components/common/TdLoader";
import SupportTicketCreate from "./SupportTicketCreate";

const SupportTickets = () => {
  const dispatch = useDispatch();

  //---> @Get customer information from redux store
  const userData = useSelector(
    (state) => state.persistedReducer.auth?.currentUser.customer
  );

  //---> @Get customer support ticket from redux store
  const tickets = useSelector((state) => state.customer.tickets);

  //---> @Get IspOwner staffs from redux store
  const staffs = useSelector((state) => state.customer.staffs);

  //---> @Get IspOwner support ticket category from redux store
  const category = useSelector((state) => state.customer.category);

  //====================|| Local State ||====================//

  //---> Loading state
  const [isLoading, setIsLoading] = useState(false);

  //---> Support ticket message show state
  const [seeMore, setSeeMore] = useState(false);

  //---> Modal handle state
  const [show, setShow] = useState(false);

  //====================|| Function ||====================//

  //---> IspOwner single staff find
  const assignedStaffFind = (id) => {
    const singleStaff = staffs?.find((staff) => staff[id]);

    return singleStaff?.[id];
  };

  //---> IspOwner single support category find
  const supportCategoryFind = (id) => {
    const singleCategory = category?.find((item) => item.id === id);

    return singleCategory;
  };

  //====================|| Api Call's ||====================//
  useEffect(() => {
    //---> @Get Customer support ticket
    !tickets?.length && getCustomerSupportTicket(dispatch, setIsLoading);

    //---> @Get IspOwner staff
    !staffs?.length &&
      getIspOwnerUsers(dispatch, userData?.ispOwner?.id, setIsLoading);

    //---> @Get IspOwner support ticket category
    !category?.length &&
      getIspOwnerTicketCategory(dispatch, userData?.ispOwner?.id, setIsLoading);
  }, []);

  return (
    <>
      <div>
        <div className="d-flex justify-content-between p-3 bg-primary bg-opacity-75">
          <div className="text-white fs-5">Support Tickets</div>
          <div className="d-flex gap-2 text-white">
            <div>
              <PlusCircle
                size={22}
                title="Refresh"
                onClick={() => setShow(true)}
              />
            </div>
          </div>
        </div>

        {isLoading ? (
          <TdLoader />
        ) : tickets?.length > 0 ? (
          tickets?.map((item) => (
            <div className="position-relative shadow-sm bg-body p-2 mt-2">
              <div className="displayGrid2">
                <div>
                  <div>
                    <span>Ticket: {item?.ticketId}</span>
                    <p>
                      <span>
                        Assign Employee:{" "}
                        {item?.assignedStaff ? (
                          assignedStaffFind(item?.assignedStaff)?.name
                        ) : (
                          <span className="text-danger">NOT ASSIGN YET</span>
                        )}
                      </span>
                    </p>
                  </div>
                  <div className="dashed-box my-1">
                    <span>
                      Category:{" "}
                      {item?.ticketCategory
                        ? supportCategoryFind(item?.ticketCategory)?.name
                        : "N/A"}
                    </span>
                    <span>Type: {item?.ticketType}</span>
                  </div>
                </div>

                <div className="ticket-message">
                  <span className="small ticket-message-title">Message</span>
                  <div className="d-flex flex-wrap" id="ticket-message">
                    <p>
                      {item?.message ? (
                        <>
                          {seeMore ? (
                            <>
                              {item.message}{" "}
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSeeMore(false);
                                }}
                              >
                                See less
                              </a>
                            </>
                          ) : (
                            <>
                              {item.message.split(" ").slice(0, 20).join(" ")}
                              {item.message.split(" ").length > 20 && (
                                <>
                                  ...{" "}
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setSeeMore(true);
                                    }}
                                  >
                                    See more
                                  </a>
                                </>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        "No message available"
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <span
                id="ticket-status"
                className={`chip bg-${
                  item?.status === "pending"
                    ? "warning"
                    : item?.status === "processing"
                    ? "secondary"
                    : "success"
                }`}
              >
                {item?.status?.toUpperCase()}
              </span>
            </div>
          ))
        ) : (
          <h6 className="text-center my-3">No Data Found!</h6>
        )}
      </div>

      {show && (
        <SupportTicketCreate
          show={show}
          setShow={setShow}
          customer={userData}
        />
      )}
    </>
  );
};

export default SupportTickets;
