import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";
import "./login.css";
import { PersonLock, Phone } from "react-bootstrap-icons";
import { userLogin } from "../../../features/customer";
import NetFeeCustomer from "../../../assets/img/netFeeCustomer.png";
import Loader from "../../../components/common/Loader";

//---> DEFAULT VALUE
const DEFAULT_VALUES = {
  mobile: "",
  password: "",
};

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //---> Form validation Schema
  const validationSchema = Yup.object({
    mobile: Yup.string()
      .length(11, "Write 11 digit mobile number")
      .required("Mobile field cannot be empty"),
    password: Yup.string().required("Password field cannot be empty"),
  });

  //====================|| Local State ||====================//

  //---> Loading state
  const [isLoading, setIsLoading] = useState(false);

  //---> Password show and hide handle state
  const [passInputType, setPassInputType] = useState("password");

  //---> Login form handler hook form with validation and default values
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: DEFAULT_VALUES,
  });

  //====================|| Function ||====================//

  //---> Customer profile login handler
  const handleCustomerProfileLogin = (loginData) => {
    userLogin(dispatch, loginData, setIsLoading);
  };

  return (
    <div className="login-page">
      <ToastContainer position="top-right" theme="colored" />

      <div className="login-form-container">
        <div className="profile-icon">
          <img src={NetFeeCustomer} />
        </div>

        <h4 className="text-white text-center mb-4">নেটফি গ্রাহক</h4>

        <form onSubmit={handleSubmit(handleCustomerProfileLogin)} id="login">
          <div className="custom-input-group">
            <Phone />
            <input {...register("mobile")} type="text" placeholder="মোবাইল" />
          </div>
          {errors.mobile && (
            <small className="text-danger d-block mb-2">
              {errors.mobile.message}
            </small>
          )}

          <div className="custom-input-group">
            <PersonLock />
            <input
              {...register("password")}
              type={passInputType}
              placeholder="পাসওয়ার্ড"
            />
          </div>
          {errors.password && (
            <small className="text-danger d-block mb-2">
              {errors.password.message}
            </small>
          )}

          <div className="form-check mb-4">
            <input
              className="form-check-input"
              type="checkbox"
              id="showPass"
              onChange={(e) =>
                setPassInputType(e.target.checked ? "text" : "password")
              }
            />
            <label className="form-check-label" htmlFor="showPass">
              ডিউ পাসওয়ার্ড
            </label>
          </div>

          <button type="submit" className="btn-login" form="login">
            {isLoading ? <Loader /> : "লগিন"}
          </button>
        </form>
      </div>

      <div className="wave-container" />
    </div>
  );
};

export default Login;
