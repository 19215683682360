import moment from "moment";

// current date
const date = new Date();

// customer day left filtering in current date
export const getCustomerDayLeft = (billDate) => {
  //current day
  const currentDay = new Date(
    new Date(moment(date).format("YYYY-MM-DD"))
  ).getTime();

  // // billing day
  const billDay = new Date(
    new Date(moment(billDate).format("YYYY-MM-DD"))
  ).getTime();

  const diffInMs = billDay - currentDay;

  // // bill day left
  const dayLeft = Math.round(diffInMs / (1000 * 60 * 60 * 24));

  return dayLeft;
};
