import React, { useEffect } from "react";
import ComponentCustomModal from "../../components/common/customModal/ComponentCustomModal";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import apiLink from "../../api/apiLink";
import { useBkash } from "react-bkash";
import customerBillMonth from "../dashboard/customerBillMonth";
import { sslCommerzPayment } from "../../features/customer";
import { toast } from "react-toastify";
import Loader from "../../components/common/Loader";

const monthOptions = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
].map((month) => ({ value: month, label: month }));

const Payment = ({ show, setShow, customer }) => {
  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required("Amount is required")
      .min(1, "The amount must be equal to or greater than the monthly fee"),
    month: Yup.array()
      .min(1, "Please select at least one month")
      .required("Month selection is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      amount: 0,
      month: [],
    },
  });

  useEffect(() => {
    if (customer) {
      const calculatedAmount =
        customer?.balance > 0 && customer?.balance < customer?.monthlyFee
          ? customer?.monthlyFee - customer?.balance
          : customer?.balance < 0
          ? customer?.monthlyFee + Math.abs(customer?.balance)
          : customer?.monthlyFee;

      reset({
        amount: calculatedAmount,
        month: customerBillMonth(customer) || [],
      });
    }
  }, [customer, reset]);

  const { error, loading, triggerBkash } = useBkash({
    onSuccess: () => (window.location.href = "/payment/success"),
    onClose: () => (window.location.href = "/"),
    bkashScriptURL:
      "https://scripts.pay.bka.sh/versions/1.2.0-beta/checkout/bKash-checkout.js",
    onCreatePayment: async () => {
      const bill = watch();
      try {
        const payment = {
          amount: Number(bill.amount),
          merchantInvoiceNumber: Date.now(),
          intent: "sale",
          ispOwnerId: customer?.ispOwner?.id,
          name: customer?.name,
          billType: "bill",
          customer: customer?.id,
          user: customer?.id,
          userType: customer?.userType,
          medium: customer?.ispOwner?.bpSettings?.paymentGateway?.gatewayType,
          month: bill.month?.map((item) => item.value).join(","),
          paymentStatus: "pending",
          collectedBy: "customer",
        };

        const response = await apiLink.post(`bkash/createPayment`, payment);

        if (response.data?.statusCode === "0000") {
          window.location.href = response.data?.bkashURL;
        }
        return response.data;
      } catch (err) {
        toast.error(err.message);
        return null;
      }
    },
    onExecutePayment: async (paymentID) => {
      const billData = {
        amount: customer?.monthlyFee,
        name: customer?.name,
        billType: "bill",
        customer: customer?.id,
        ispOwner: customer?.ispOwner.id,
        user: customer?.id,
        userType: customer?.userType,
        medium: customer?.ispOwner?.bpSettings?.paymentGateway?.gatewayType,
        paymentStatus: "pending",
        mikrotikPackage: customer?.mikrotikPackage,
        collectorId: customer?.ispOwner.id,
      };
      return await apiLink.post(
        `bkash/executePayment?paymentID=${paymentID}`,
        billData
      );
    },
  });

  useEffect(() => {
    if (error) {
      localStorage.setItem("paymentError", error?.message);
      window.location.href = "/payment/error";
    }
  }, [error]);

  const triggerSSLCommerz = async () => {
    const bill = watch();
    const data = {
      amount: Number(bill?.amount),
      name: customer.name,
      billType: "bill",
      customer: customer.id,
      ispOwner: customer.ispOwner.id,
      user: customer.id,
      userType: customer.userType,
      medium: customer.ispOwner.bpSettings?.paymentGateway?.gatewayType,
      month: bill.month?.map((item) => item.value).join(","),
      paymentStatus: "pending",
      mikrotikPackage: customer.mikrotikPackage,
      collectorId: customer.ispOwner.id,
    };

    sslCommerzPayment(data);
  };

  const formSubmit = () => {
    customer?.ispOwner?.bpSettings?.paymentGateway?.gatewayType === "bKashPG"
      ? triggerBkash()
      : triggerSSLCommerz();
  };

  return (
    <ComponentCustomModal show={show} setShow={setShow} keyboard centered>
      <form onSubmit={handleSubmit(formSubmit)}>
        <div className="displayGrid">
          <div>
            <label className="form-check-label fw-normal">
              <small>Total Bill Amount</small>
              <span className="text-danger"> *</span>
            </label>
            <input
              {...register("amount")}
              className="form-control"
              type="number"
              min={1}
            />
            {errors.amount && (
              <small className="text-danger">{errors.amount.message}</small>
            )}
          </div>

          <div>
            <label className="form-check-label fw-normal">
              <small>Select Bill Month</small>
              <span className="text-danger"> *</span>
            </label>
            <Controller
              name="month"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  className="mt-0 text-dark"
                  options={monthOptions}
                  isMulti
                  placeholder="Select Bill Month"
                  isSearchable
                  value={field.value}
                  onChange={(selectedOptions) =>
                    setValue("month", selectedOptions || [])
                  }
                />
              )}
            />
            {errors.month && (
              <small className="text-danger">{errors.month.message}</small>
            )}
          </div>

          <div className="displayGrid1 float-end">
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => setShow(false)}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-outline-success">
              {loading ? <Loader /> : "Pay"}
            </button>
          </div>
        </div>
      </form>
    </ComponentCustomModal>
  );
};

export default Payment;
