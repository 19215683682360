import React from "react";
import { Link } from "react-router-dom";
import CancelPayment from "../../assets/img/payment/Cancel-Payment.png";

export default function Cancel() {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center text-center">
      <div className="w-100" style={{ maxWidth: "396px" }}>
        <img src={CancelPayment} alt="root-billing" className="img-fluid" />
      </div>
      <div className="mt-4 p-1">
        <h1 className="fw-bold">Your payment has been cancelled</h1>
        <p
          className="text-secondary"
          style={{ maxWidth: "70%", margin: "0 auto" }}
        >
          If this was a mistake, please try again or contact support.
        </p>
        <Link to="/dashboard" className="btn btn-primary mt-4">
          Back To Home
        </Link>
      </div>
    </div>
  );
}
