import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateOutlet from "./PrivateOutlet";
import Login from "./pages/auth/login/Login";
import NotFound from "./pages/NotFound/NotFound";
import Execute from "./pages/execute/Execute";
import Success from "./pages/payment/Success";
import Cancel from "./pages/payment/Cancel";
import Failed from "./pages/payment/Failed";
import Dashboard from "./pages/dashboard/Dashboard";
import Package from "./pages/package/Package";
import Report from "./pages/report/Report";
import SupportTickets from "./pages/support-ticket/SupportTickets";
import Error from "./pages/payment/Error";
import Profile from "./pages/profile/Profile";
import SupportNumber from "./pages/support-number/SupportNumber";

function App() {
  const user = useSelector((state) => state.persistedReducer.auth?.currentUser);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route
        path="/login"
        element={!user ? <Login /> : <Navigate to="/dashboard" />}
      />
      <Route element={<PrivateOutlet />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/report" element={<Report />} />
        <Route path="/package" element={<Package />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/support/number" element={<SupportNumber />} />
        <Route path="/support/ticket" element={<SupportTickets />} />
      </Route>

      <Route path="/payment/execute" element={<Execute />} />
      <Route path="/payment/success" element={<Success />} />
      <Route path="/payment/error" element={<Error />} />
      <Route path="/payment/cancel" element={<Cancel />} />
      <Route path="/payment/failed" element={<Failed />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
