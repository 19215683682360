import React from "react";
import { Link } from "react-router-dom";
import SuccessfulPayment from "../../assets/img/payment/Successful-Payment.png";

const Success = () => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center text-center">
      <div className="w-100" style={{ maxWidth: "396px" }}>
        <img src={SuccessfulPayment} alt="root-billing" className="img-fluid" />
      </div>
      <div className="mt-4">
        <h1 className="fw-bold text-success">Payment is Successful</h1>
        <p
          className="text-secondary"
          style={{ maxWidth: "70%", margin: "0 auto" }}
        >
          Complete your payment to unlock features.
        </p>
        <Link to="/dashboard" className="btn btn-outline-success mt-4">
          Back To Home
        </Link>
      </div>
    </div>
  );
};

export default Success;
