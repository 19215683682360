import { toast } from "react-toastify";
import apiLink, { publicRequest } from "../api/apiLink";
import {
  getCustomerBillReportSuccess,
  getCustomerChangePackageSuccess,
  getCustomerCurrentSessionSuccess,
  getCustomerProfileSuccess,
  getCustomerRouterConnectivitySuccess,
  getCustomerSupportTicketSuccess,
  getIspOwnerPackageSuccess,
  getIspOwnerStaffSuccess,
  getIspOwnerSupportNumberSuccess,
  getIspOwnerTicketCategorySuccess,
  postCustomerSupportTicketSuccess,
} from "./customerApiSlice";
import { logInSuccess } from "./authSlice";

//---> @USER PROFILE LOGIN
export const userLogin = async (dispatch, loginData, setIsLoading) => {
  setIsLoading(true);
  try {
    const res = await publicRequest.post(`auth/login`, loginData);
    localStorage.setItem(
      "netFeeToken",
      JSON.stringify(res.data?.access?.token)
    );

    dispatch(logInSuccess(res.data));
  } catch (error) {
    toast.error(error.response?.data.message);
  }
  setIsLoading(false);
};

//---> @GET CUSTOMER PROFILE
export const getCustomerProfile = async (dispatch, customer, setIsLoading) => {
  setIsLoading(true);
  try {
    const res = await apiLink.get(`customer/profile/${customer}`);

    dispatch(getCustomerProfileSuccess(res.data?.data));
  } catch (error) {
    toast.error(error?.response?.data.message);
  }
  setIsLoading(false);
};

//---> @POST CUSTOMER PROFILE PASSWORD RESET
export const profilePasswordReset = async (
  data,
  setIsLoading,
  setShow,
  setStartRedirect,
  setRedirectTime
) => {
  setIsLoading(true);
  try {
    await apiLink.post(`auth/update-password`, data);

    setShow(false);

    setStartRedirect(true);
    let redirectTime = 10;
    const interval = setInterval(() => {
      setRedirectTime(redirectTime - 1);
      redirectTime--;
      if (redirectTime === 0) {
        clearInterval(interval);
        userLogout();
      }
    }, 1000);

    toast.success("Password reset successful");
  } catch (error) {
    toast.error(error?.response?.data.message);
  }
  setIsLoading(false);
};

//---> @GET CUSTOMER ROUTER SESSION INTERFACE
export const getCustomerCurrentSession = async (
  dispatch,
  customer,
  setIsLoading
) => {
  setIsLoading(true);
  try {
    const res = await apiLink.get(
      `customer/mikrotik/currentSession?customerId=${customer}`
    );

    dispatch(getCustomerCurrentSessionSuccess(res.data?.data[0]));
  } catch (error) {
    toast.error(error?.response?.data.message);
  }
  setIsLoading(false);
};

//---> @GET CUSTOMER ROUTER ACTIVE CONNECTION
export const getCustomerRouterConnectivity = async (dispatch, setIsLoading) => {
  setIsLoading(true);
  try {
    const res = await apiLink.get(`customer/mikrotik/routerConnectivity`);

    dispatch(getCustomerRouterConnectivitySuccess(res.data?.data[0]));
  } catch (error) {
    toast.error(error?.response?.data.message);
  }
  setIsLoading(false);
};

//---> @GET ISP OWNER PACKAGES
export const getPackagesByIspOwner = async (dispatch, setIsLoading) => {
  setIsLoading(true);
  try {
    const res = await apiLink.get(`customer/package`);

    dispatch(getIspOwnerPackageSuccess(res.data?.data));
  } catch (error) {
    toast.error(error.response);
  }
  setIsLoading(false);
};

//---> @POST CUSTOMER PACKAGE CHANGE
export const updateCustomerPackage = async (data, setLoading, setShow) => {
  setLoading(true);
  try {
    await apiLink.post(`customer/package`, data);

    toast.success("Waiting for Admin Acceptance");
    setShow(false);
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
  setLoading(false);
};

//---> @GET CUSTOMER PACKAGE CHANGE
export const getCustomerChangePackage = async (
  dispatch,
  customer,
  setLoading
) => {
  setLoading(true);
  try {
    const res = await apiLink.get(`customer/get/changes/package/${customer}`);

    dispatch(getCustomerChangePackageSuccess(res.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
  setLoading(false);
};

//---> @GET CUSTOMER MONTHLY BILL REPORT
export const getCustomerBillReport = async (dispatch, setLoading) => {
  setLoading(true);
  try {
    const res = await apiLink(`customer/paymentHistory`);

    dispatch(getCustomerBillReportSuccess(res.data.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
  setLoading(false);
};

//---> @GET ISP OWNER SUPPORT NUMBER's
export const ispOwnerSupportNumbers = async (
  dispatch,
  ispOwner,
  setLoading
) => {
  setLoading(true);
  try {
    const res = await apiLink.get(`customer/customer/support/${ispOwner}`);

    dispatch(getIspOwnerSupportNumberSuccess(res.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
  setLoading(false);
};

//---> @GET CUSTOMER SUPPORT TICKET
export const getCustomerSupportTicket = async (dispatch, setLoading) => {
  setLoading(true);
  try {
    const res = await apiLink.get(`customer/supportTicket`);

    dispatch(getCustomerSupportTicketSuccess(res.data?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
  setLoading(false);
};

//---> @GET ISP OWNER STAFF
export const getIspOwnerUsers = async (dispatch, ispOwnerId, setLoading) => {
  setLoading(true);
  try {
    const res = await apiLink.get(`/ispOwner/user-map/${ispOwnerId}`);

    dispatch(getIspOwnerStaffSuccess(res.data?.usersMap));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
  setLoading(false);
};

//---> @GET ISP OWNER TICKET CATEGORY
export const getIspOwnerTicketCategory = async (
  dispatch,
  ispOwnerId,
  setIsLoading
) => {
  setIsLoading(true);
  try {
    const res = await apiLink.get(`ispOwner/get/ticket/category/${ispOwnerId}`);

    dispatch(getIspOwnerTicketCategorySuccess(res.data));
  } catch (error) {
    toast.error(error.res?.data.message);
  }
  setIsLoading(false);
};

//---> @POST CUSTOMER SSL COMMERZ BILL PAYMENT
export const sslCommerzPayment = async (data, setLoading) => {
  try {
    const res = await apiLink.post(`customer/pg/monthlyBill`, data);

    window.location.href = res.data.data;
  } catch (error) {
    toast.error(error.response?.data.message);
  }
};

//---> @POST CUSTOMER SUPPORT TICKET
export const createCustomerSupportTicket = async (
  dispatch,
  data,
  setLoading,
  setShow
) => {
  setLoading(true);
  try {
    const res = await apiLink.post(`customer/supportTicket`, data);

    dispatch(postCustomerSupportTicketSuccess(res.data?.data));
    setShow(false);
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
  setLoading(false);
};

//---> @USER PROFILE LOGOUT
export const userLogout = async () => {
  localStorage.removeItem("persist:root");
  localStorage.removeItem("netFeeToken");
  localStorage.removeItem("nf:textR");
  window.location.reload();
};
