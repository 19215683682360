import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { Footer } from "./components/footer/Footer";

export default function PrivateOutlet() {
  const user = useSelector((state) => state.persistedReducer.auth?.currentUser);
  return user ? (
    <div className="vh-100 d-flex flex-column">
      <div className="flex-grow-1">
        <Outlet />
      </div>

      <Footer />
    </div>
  ) : (
    <Navigate to="/login" />
  );
}
