import React, { useState } from "react";
import ComponentCustomModal from "../../components/common/customModal/ComponentCustomModal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { profilePasswordReset } from "../../features/customer";
import { toast } from "react-toastify";
import Loader from "../../components/common/Loader";

const ChangePassword = ({
  show,
  setShow,
  setStartRedirect,
  setRedirectTime,
}) => {
  //====================|| Validation Schema ||====================//
  const validationSchema = Yup.object({
    oldPassword: Yup.string().required("Old password is required"),
    newPassword: Yup.string()
      .min(8, "New password must be at least 8 characters")
      .required("New password is required"),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref("newPassword")], "Passwords must match")
      .required("Confirm password is required"),
  });

  //====================|| Local State ||====================//

  const [isLoading, setIsLoading] = useState(false);

  //====================|| Form Hook ||====================//
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });

  //====================|| Submit Handler ||====================//
  const handleCustomerProfilePasswordReset = (formValues) => {
    if (formValues.newPassword !== formValues.confirmNewPassword) {
      toast.error("New password and confirm password must match");
      return;
    }

    const sendingData = {
      oldPassword: formValues.oldPassword,
      newPassword: formValues.newPassword,
    };

    profilePasswordReset(
      sendingData,
      setIsLoading,
      setShow,
      setStartRedirect,
      setRedirectTime
    );
  };

  return (
    <ComponentCustomModal show={show} setShow={setShow} centered>
      <form
        className="displayGrid"
        onSubmit={handleSubmit(handleCustomerProfilePasswordReset)}
      >
        <div>
          <label className="small" htmlFor="newPassword">
            Old password <span className="text-danger me-4">*</span>
          </label>
          <Controller
            name="oldPassword"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                className={`form-control ${
                  errors.oldPassword ? "is-invalid" : ""
                }`}
                placeholder="********"
              />
            )}
          />
          {errors.oldPassword && (
            <div className="text-danger">{errors.oldPassword.message}</div>
          )}
        </div>

        <div>
          <label className="small" htmlFor="newPassword">
            New password <span className="text-danger me-4">*</span>
          </label>
          <Controller
            name="newPassword"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                className={`form-control ${
                  errors.newPassword ? "is-invalid" : ""
                }`}
                placeholder="********"
              />
            )}
          />
          {errors.newPassword && (
            <div className="text-danger">{errors.newPassword.message}</div>
          )}
        </div>

        <div>
          <label className="small" htmlFor="newPassword">
            Confirm new password <span className="text-danger me-4">*</span>
          </label>
          <Controller
            name="confirmNewPassword"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                className={`form-control ${
                  errors.confirmNewPassword ? "is-invalid" : ""
                }`}
                placeholder="********"
              />
            )}
          />
          {errors.confirmNewPassword && (
            <div className="text-danger">
              {errors.confirmNewPassword.message}
            </div>
          )}
        </div>

        <div className="displayGrid1 float-end">
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => setShow(false)}
            disabled={isLoading}
          >
            Cancel
          </button>

          <button
            type="submit"
            className="btn btn-outline-success"
            disabled={isLoading}
          >
            {isLoading ? <Loader /> : "Save"}
          </button>
        </div>
      </form>
    </ComponentCustomModal>
  );
};

export default ChangePassword;
